h1,h2,h3,h4,h5,p {
    font-family: 'Poppins', sans-serif;
}
body{
    font-family: 'Poppins', sans-serif;
}
.responsive {
    height:100%;
    width:auto;
}
a{
    text-decoration: none;
}
/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
   width: 50%;
    font-size: 50px;
    bottom: 25vh;
    position: relative;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 5%; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 50% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #a07f14 }
  }

 

@media only screen and (max-width: 600px) {
    .typewriter h1 { 
        width: 70%;      
         font-size: 20px;
         bottom: 5vh;
         position: relative;
         margin: 0 3%;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 70% }
  }
  @keyframes blink-caret {
    from, to { border-color: transparent }
    70% { border-color: #a07f14 }
  }

}
@media (max-width:1250px) and (min-width:601px) {
    .typewriter h1 { 
        width: 90%;      
         font-size: 20px;
         bottom: 5vh;
         position: relative;
         margin: 0 3%;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 90% }
  }
  @keyframes blink-caret {
    from, to { border-color: transparent }
    90% { border-color: #a07f14 }
  }

}